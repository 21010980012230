.contact{
    width: 100%;
    min-height: 120vh;
    padding: 100px 0px 75px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact_input input {
    height: 43px;
    border: 1px solid #006BC2;
    
}
.contact_input textarea{
    border: 1px solid #006BC2;
}
.contact_input input:focus , .contact_input textarea:focus{
    outline: none;
    box-shadow: none;
    
}