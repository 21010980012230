.purches_img{
    width: 100%;
    height:300px;
    object-fit: cover;
    display: block;
    margin: auto;
    border-radius: 10px;

}
.book_img{
    width: 100%;
    height: 320px;
    object-fit: contain;
    border-radius: 5px;
    display: block;
    margin: auto;

}
.flex-end{
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}
.display_flex{
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    
}
.form_h{
    height: 45px !important;
}
.word_r{
    word-wrap: break-word !important;
}



.carousel-control-prev-icon{
    background-color: blue;
    width: 20px  ;
    height: 45px;
    /* background-image:url('https://res.cloudinary.com/dlib0cjnl/image/upload/v1699759392/icons8-chevron-left-30_xavenl.png') */
}

.carousel-control-next-icon{
    background-color: blue;
    width: 20px  ;
    height: 45px;
   
}

.book_card{
    position: relative;
}
.book_card_abs{
    position: absolute;
    top:10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ghostwhite;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: center;
    align-items: center;
}




.dropdown {
    position: relative;
    display: inline-block;
    
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right:0;
    background-color:white;
    min-width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   
  }
  
  .dropdown-content a {
    
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dropdown-content a:hover {
    background-color: rgba(221, 221, 221,0.5);
   
}
  
  .dropdown:hover .dropdown-content {display: block;}

  .share-icon{
    font-size: 12px;
  }

  .text_fb{
    color:#4064AC !important;
  }
  
  .text_wh{
    color:#54CF62 !important;
  }
  .text_tw{
    color:#1DA1F2 !important;
  }
 .book_cover{
  width: 100%;
 display: block;
 margin: auto;
 background-color: ghostwhite;
 border-radius: 10px;
 padding: 10px 5px;
 position: relative;
 }
 .book_cover img{
  width: 100%;
    height: 450px;
    object-fit: contain;
    border-radius: 5px;
    display: block;
    margin: auto;
 }


.form-control {
  border:1px solid #006BC2 !important;
  
}
 .form-control:focus{
  
box-shadow: none;
  
}