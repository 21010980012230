.img_text{
    
    position: relative;
    padding: 5px;
}
.img_text_page{
    position: absolute;
    left: 0;
    bottom: 0;
    width:calc(100% - 10px);
    height: auto;
    background-color: rgba(0,0,0,0.5);
    margin-left: 5px;
    margin-bottom: 5px;
    color: white;
    font-size: 16px;
    text-align: center;
    padding: 5px;
}