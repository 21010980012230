* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
.new_invo h4{
font-weight: 500;
margin: 5px 0;
font-size: 16px;
}
.new_invo h4 span{
color: #006BC2;
}
  .new_invo .itemflex {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(27%, 1fr));
    gap: 30px;
    margin-top: 10px;
  }

  .new_invo .boxitem {
    width: 100%;
    height: auto;
    /* border: 1px solid brown; */
    padding: 10px;
    border-radius: 5px;
    
  }
  .new_invo .f_bold {
    font-weight: bolder;
    font-size: 20px;
  }
  .new_invo .fw_bold {
    font-weight: 600;
  }
 .new_invo p {
    font-size: 14px;
  }
 .new_invo .m-0 {
    margin:  0px;
  }
 .new_invo .mb-3 {
    margin-bottom: 15px;
  }
 .new_invo .right_div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    line-height: 35px;
  }
  .new_invo .right_div .bg-light {
    background-color: #eee;
    padding: 5px 10px;
  }
  .new_invo .flex_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px;
  }
  .new_invo .logo {
    width: 150px;
    height: 70px;
  }
  .new_invo .rightdiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 600;
  }

 
  .new_invo .mylist{
    margin-top: 30px;
    
  }
  .new_invo .mylist thead {
    background-color: #006BC2;
    height: 40px;
    color:white;
   
  }
  .new_invo .w_20 {
    width: 90px !important;
    font-weight: 500 !important;
  }
  
  .new_invo .w_60 {
    width: 300px !important;
    font-weight: 500 !important;
  }
  .new_invo .w_40 {
    width: 200px !important;
    font-weight: 500 !important;
  }

  .new_invo table {
    width: 100%;
    border-collapse: collapse;
    
        table-layout: fixed;
        overflow-x: scroll;
      
  }
  .new_invo .item_img {
    width: 75px;
    height: 75px;
    object-fit: contain;
    border-radius: 10px;
  }
  .new_invo .textdark {
    color: #555 !important;
    text-decoration: none;
  }
  .new_invo .textdark:hover {
    text-decoration: none;
  }
  .new_invo .total_div {
    width: 280px;
/* background-color: aqua; */
    
    padding: 15px;
    margin-right: -50px;
  }
  .new_invo .d-flex{
    display: flex;
    align-items: center;
    
  }
  
  
  .new_invo tbody td {
    padding: 15px 0;
    
  }
  
  .new_invo .fn_20{
        font-size: 14px;
        font-weight: 600;
    }
  
    .new_invo  .dd_flex
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
    }
    .new_invo  .boxitem .right_div .fp{
line-height:normal !important;
    }
    .new_invo  tbody tr{
        background: ghostwhite;
        height: 100px;
        padding: 5px ;
    }
    .new_invo tbody tr td{
        text-align: center;
        font-size: 12px;
    }
    .new_invo thead tr th{
      font-size: 12px;
    }
    .new_invo{
        width: 100%;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(85, 99, 206, 0.5);
        z-index: 99999;
    }

    .new_invo .invoice_box {
        /* max-width: 1000px; */
        width: 90%;
        height: 90vh;
        overflow-y: auto;
        margin: auto;
        padding:15px 20px  ;
    
        font-size: 20px;
    
        margin-top: 50px;
        color: #555;
        background-color: white;
      }