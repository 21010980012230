.top__rate{
  width: 100%;
  height: auto;
  
  padding: 25px 0;
}



.top__rate{
  /* width: 100px;
  height: 150px; */
  position: relative;
 
  
}
.top__rate .card_img{
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
 
  

}
.top_card_link{
  border: 1px solid rgba(168, 166, 166,0.5);
  border-radius: 5px;
}
.top_card_link a:hover{
  color:black;
  text-decoration: none;
  
}
.top_p{
margin: 0;
display: -webkit-box;
overflow: hidden;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;

}


.dd_flex{
display: flex;
justify-content: space-between;
align-items: center;
}

.dd_flex a{
font-size: 30px;
color: #B8232B;
}
.d_flex{
display: flex;

}











.top_rate_nav_link:hover a{
  display: block;
}






.top__rate .swiper-slide
{
  width: 14%;
  transition: all 0.5s;
  
}
.link_card{
color:black;
}
.link_card:hover{
color: #000;
text-decoration: none;
}


.ads_img{
width: 100%;
height: 150px ;
border-radius: 5px;
/* object-fit: ; */
}

@media  (min-width:1000px) {
.top__rate .swiper-slide:hover{
  width: 20%;
  box-shadow: 0px 4px 7px grey;
}
}
@media  (min-width:1200px) {
.top__rate .swiper-slide:hover{
  width: 16%;
  box-shadow: 0px 4px 7px grey;
}
}

@media (max-width:1200px) {
.top__rate .swiper-slide
{
  width: 18%;
}


}


@media (max-width:1000px) {
  .top__rate .swiper-slide
  {
    width: 20%;
  }
  
}

@media (max-width:800px) {
  .top__rate .swiper-slide
  {
    width: 25%;
  }
  
}

@media (max-width:550px) {
  .top__rate .swiper-slide
  {
    width: 35%;
  }
  
}
@media (max-width:450px) {
  .top__rate .swiper-slide
  {
    width: 45%;
  }
  
}



.banar{
  width: 98%;
  height: auto;
 display: block;
 margin: auto;
 box-shadow: 0px 2px 4px grey;
 margin-top: 10px;
}

.banar .swiper-slide
{
  width: 50%;
}

.banar__slider img{
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.banar_link{
  position: relative;
  width: 100%;
  height: 100%;
}




.swiper-button-prev
{

color: #006BC2 !important;
position: absolute;
left:-2px !important ;
top: 130px !important;
height: 100px !important;
 
background-color: white;

}

.swiper-button-next
{

color: #006BC2 !important;
position: absolute;
right:-2px !important ;
top: 130px !important;
height: 100px !important;
 
background-color: white;

}



@media (max-width:900px) {
 
.banar .swiper-slide
{
  width: 100%;
}
.banar__slider img{
  
  height: 250px;
 
}

.swiper-button-prev
{


top: 125px !important;
height: 50px !important;
 
}
.swiper-button-next
{


top: 125px !important;
height: 50px !important;
 


}
}








