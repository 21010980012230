.det_card{
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
    
}
.section_page ul{
    /* list-style-type: none; */
    margin-left: 15px;
}
.section_page ul li{
    margin: 15px 0;
}
.section_page ul li a{
    color:#2c3e50;
    
}
.section_page ul li a:hover{
    color:#2c3e50 !important;
    background: none !important;
    text-decoration: underline !important;
}
.cur_Det_img{
    width: 100%;
    height: 500px;
    object-fit: contain;
    border-radius: 10px;
}
.img_rel{
    position: relative;
    
}
.img_abs{
    position: absolute;
    width: 100%;
    height: 100%;
   background-color: rgba(0, 0, 0,0.4);
   border-radius: 10px;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}
.play_btn{
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}



.play_btn::before {
    content: "";
    position: absolute;
    /* top: -37%;
    right: -20px; */

    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 50%;
    animation: ph 1s linear infinite;
}

.play_btn::after {
    content: "";
    position: absolute;
    /* top: -37%;
    right: -20px; */

    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 50%;
    /* animation: ph 1s linear infinite; */
    animation: ph 1s linear infinite;
    animation-delay: 0.4s;
}

@keyframes ph {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}


.video_prev{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0,0.4);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}


.video_prev_box{
    width: 650px;
    height: 400px;
    position: relative;
    background-color: black;
    border-radius: 15px;
}
.cross_b{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;

}

.text-sml{
    font-size: 40px;
}
@media (max-width: 768px) {
    .video_prev_box{
        width: 90%;
    }
    
    
}

@media (max-width:550px) {
    .text-sml{
        font-size: 30px;
    }
}

@media (max-width:400px) {
    .text-sml{
        font-size: 27px;
    }
}


.other_tab table {
    border-collapse: collapse !important;
  }

  @media screen and (max-width: 800px) {
    .other_tab table{
      border: 0;
    }

    .other_tab table th, .other_tab table td {
      border: 0;
      display: block;
      width: 100%;
      box-sizing: border-box;
    }


  }
 
  
  .other_tab th, .other_tab td {
    border: 1px solid black;
  }
  .text-size{
    font-size: 25px !important;
  }