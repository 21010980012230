.auth{
    width: 100%;
    min-height: 100vh;
    padding: 100px 0 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("https://edushark.in/image/loginhome.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.auth_img{
    width: 100%;
    /* height: 450px; */
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.auth_box input , .auth_box select {
    width: 100%;
    height: 43px;
    border: 1px solid #006BC2;
    padding: 0px 10px;
    /* margin-bottom: 15px; */
}
.input_btn{
    height: 43px;
    padding: 5px 10px;
    border:none;
    outline: none;
    background-color: #006BC2;
    color:white;
    font-size: 12px ;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
.input_btn:focus{
    outline: none;
}
.auth_box input:focus , .auth_box select:focus{
    outline: none;
}

@media (max-width: 767px) {
    .auth_img{
        width: 150px;
        height: 150px;
        display: block;
        margin: 15px auto;
        border-radius: 50%;
    }
    
}

.otpinput{
    width: 80%;
    height: 43px;
    border: 1px solid #006BC2;
    padding-left: 10px;
    display: block;
    margin: auto;
}
.otpinput:focus{
    outline: none;
}
.success_img{
    width: 150px;
    height: 150px;
    display: block;
    margin: auto;
    object-fit: contain;
  }
  .input_btn_green{
    height: 43px;
    padding: 5px 10px;
    border:none;
    outline: none;
    background-color: #04a930;
    color:white;
    /* font-size: 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
   

  }

  .formh{
    height: 43px;
  }

 .re_p{
    margin-top: -12px !important;
    color: #006BC2;
 }

.alertw{
    width: 80%;
    display: block;
    margin: auto;
}
 .countdown-text {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:10px auto ;
  }
  
  /* Countdown text paragraph styles */
  .countdown-text > p {
    font-size: 13px;
    font-weight: 400;
    color: #2d1d35;
  }
  
  /* Countdown text button styles */
  .countdown-text > button {
    background-color: #fff;
    border: none;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    margin-top: -20px;
  }

  @media (max-width:776px) {
    .otpinput{
        width: 90%;
    }
    .countdown-text{
        width: 90%;
    }
    .alertw{
        width: 90%;
    }
  }

  .otpbox{
    width: 100%;
    min-height:100vh;
    padding: 100px 0;
  }
  .optimg{
    width: 170px;
    height: 120px;
    margin: auto;
    display: block;
    object-fit: contain;
  }
  
  .otpform label{
    margin-bottom: -5px;
  }