
.courseimg{
  width: 100%;
  height: 250px;
  object-fit: contain;
  border-radius: 10px;
  /* filter: drop-shadow(0 0 2px grey); */
}
.div_box{
  width: 100%;
  height: 250px;
  background-color: rgba(128, 128, 128,0.5) !important;
  border-radius: 10px;
 
}

.card{
  border: none;
  border-radius: 0;
  box-shadow: 0px 2px 4px grey;
}
.hero_img_box{
  position: relative;
}
.hero_img_box_inner{
  width: 250px;
  height: 100px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  animation: updown 3s linear infinite  alternate;
}

.hero_img_box_inner_right{
  width: 200px;
  height: 80px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  right: -25px;
  top: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  animation: updown 4s linear infinite  alternate;
}
.access_hero_img_box_inner_left{
  width: 200px;
  height: 80px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  left:25px;
  bottom: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  animation: updown 4s linear infinite  alternate;
}

.access_hero_img_box_inner_right_top{
  width: 200px;
  height: 80px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  left:250px;
 
  top: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  animation: updown 4s linear infinite  alternate;
}

.hero_img_box_inner .avtar{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover !important;
}




@keyframes updown {
  0%   {
    transform: translateY(-45%);
  }
  50%  {transform: translateY(0%);}
  
  100% {transform: translateY(45%);}
}




@media (min-width: 768px) and (max-width: 992px){
  .hero_img_box_inner_right{
    width: 150px !important;
    right: -20px;
    
  }
 .inner-header .dflex h1 {
font-size: 35px !important;
  }
  
}
  
@media (max-width: 500px){
  .hero_img_box_inner_right{
    display: none;
  }
  .hero_img_box_inner{
    left: 15px;
  }
  .access_hero_img_box_inner_right_top{
    left:100px;
  }
}







.company{
    width: 100%;
    padding: 45px 0;

}
.com_slider{
    width: 80%;
  
    margin: auto;
    overflow: hidden;
    position: relative;
    display: block;
   
}
.slider_track{
display: flex;
width: calc(150px * 10);

animation: scroll 40s linear infinite  ;

}

.com_slider .slide_com{
    height: 100px;
    width: 150px;
    margin: 15px;
}
.slide_com img{
  width: 120px;
  height: 120px;
  object-fit: contain;
}

@keyframes scroll{
    0%{
transform: translatXe(0);
    }
    100%{
        transform: translateX(calc( -150px * 5));
    }
} 

.span_dis{
    text-decoration-color: #0082e6 !important;
 text-decoration: underline;
}


.home_flex{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
}

.stu_flex{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
}

.box_item{
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid #006BC2;
}
.box_item h1{
    color: #006BC2;
    font-weight: 800;
    font-size: 60px;

}
.company .card{
    border: none;
    border-radius: 0;
    box-shadow: 0px 2px 4px grey;
    border-bottom: 4px solid #0082e6;
}
.company .card h1{
    color: #006BC2;
}

.about{
    width: 100%;
    padding: 45px 0;
    position: relative;
  }
  .about::after {
    position: absolute;
    content: '';
    top: 0px;
    right: 0;
  
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url('https://www.mm2h.com/wp-content/uploads/2019/12/dark-blue-blob.png');
  
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 30%;
  }
  .aboutimg{
    width: 100%;
    height: 450px;
    object-fit: contain;
  }
  .about h2{
    font-size: 40px;
    font-weight: 800;
  }
  .about p{
    margin: 15px 0;
    color: #2c3e50;
    font-size: 20px;
  }
  .about ul{
    list-style-type: none;
  }
  .about ul li{
    
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
  
  }
  .about ul li i{
    margin-right: 15px;
    color: #0082e6;
    font-size: 25px;
  }

 .center_div{
    width: 90%;
    display: block;
    margin: auto;
  
 }

 .aboutsec{
    width: 100%;
    padding: 100px 0px 35px 0;
    background-color: #006BC2;
 }
 .aboutsec .card{
    border-radius: 0;
 }

 .card_im img{
    width: 100%;
    height: 450px;
    object-fit: contain;
    margin-top: -60px;
 }
 .card_im h1{
    font-size: 45px;
    font-weight: 700;
    color: #006BC2;
 }
 /* .dd_flex{
    display: flex;
    justify-content: center;
    flex-direction: column;
 } */

 @media (min-width:760px) and (max-width:900px) {
    .card_im img{
        margin: 0;
    }
 }

 .studentlist{
    width: 100%;
    padding: 50px 0;
 }

 .student_item{
    width: 100%;
    height: 250px;
    background-color: #006BC2;
    padding: 15px;
    border-radius: 5px;
    color: white;
    text-align: center;

 }
 .stu_img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
    margin: auto;
    background-color: #006BC2;
    border: 7px solid white;
 }
 .student_item p{
  margin-top:-10px ;
 }
 .com_img{
    width: 100px;
    height: 70px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-top: -20px;
 }



 .afterhero{
  width: 100%;
  min-height: 50vh;
  padding: 75px 0;

 }
 .afterhero h1 {
  text-align: center;
  font-size: 35px;
  color: #3F3A64;
  font-weight: 600;
 }
 .afterhero h3{
  text-align: center;
  font-size: 30px;
  color: #3F3A64;
  font-weight: 600;
 }
 .icon_img{
  width:60px;
  height: 60px;
  display: block;
  margin: auto;
  transition: all 0.5s;
 }
 .afterhero_card{
  transition: all 0.5s;
 }
 .afterhero_card h5{
  margin: 15px 0;
 color: #032542;
 }
 .afterhero_card:hover .icon_img{
  transform: translateY(-15px);
 }
 .afterhero_card:hover h5{
  color: #006BC2;
 }

 .access_img{
  width: 100%;
  height: 600px;
  border-radius: 15px;
 }
 .access_box{
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  
  
 }
 .access_box h1{
  width: 80%;
  text-align: left;
  color: #3F3A64;
  font-weight: 600;
 }
 .access_box p{
  width: 90%;
 }
 .acces_flex_items{
 margin-top: 15px;
  width: 90%;
 }
 .acces_flex_item img{
  width: 75px ;
  height: 75px;
  border-radius:15px;
  object-fit: contain;
 background-color: #F7F5F4;
 padding: 10px;
 margin-right: 25px;
 }
 .acces_flex_item{
  display: flex;
 margin: 15px 0;
  
 }
 .acces_flex_item div h5{
  color: #032542;
 }
 .acces_flex_item div p{
  color: grey;
 }
 .access_box ul{
  margin-left: 15px;
 }
 .access_box ul li{
  margin: 15px 0;
 }
.btn-warning{
  background-color: #FED205 !important;
  border: none;
 }
 .access_rel{
  position: relative;
 }


 @media (max-width:992px) {
  
  .access_box h1{
    width: 100%;
  }
  .access_box p{
    width: 100%;
  }
  
 }

 @media (max-width:768px) {
  
  
  .acces_flex_items{
    width: 100%;
  }

  .access_img{
    height: 450px;
   }
  
 }




 .big_text {
  background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: 900;
  margin-top: 15px;
}

.big_text p {
  font-size: 8vw;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
}

@supports (background-clip: text) or (-webkit-background-clip: text) {
  .fancy {
    background-image: url("data:image/svg+xml,%3Csvg width='2250' height='900'  fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2300FDCF'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2300FDCF'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@media (max-width: 768px) {
  .big_text p{
    font-size: 65px;
  }
  
}

@media (max-width: 550px) {
  .big_text{
    min-height: 30vh;
  }
  .big_text p{
    font-size:50px;
  }
  
}

@media (max-width: 400px) {
  .big_text{
    min-height: 30vh;
  }
  .big_text p{
    font-size:40px;
  }
  
}