.stu_home{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top:0;
    left: 0;
    background-color: white;
    z-index: 999999;
}
.form_height{
    height: 42px;
}
.stu_gif{
width: 80%;
/* height: 350px; */
display: block;
margin: auto;
}

.welcome_dash{
    width: 100%;
    height: 200px;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
    border-radius: 10px;
    display: flex;
   justify-content: center;
    flex-direction: column;
    position: relative;
}
.left_stu_dash{
    position: absolute;
    left: 0;
    z-index: 2;
    

}
.right_stu_dash{
    position: absolute;
    right: 0;
    z-index: 1;
}
.right_stu_dash img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding: 15px;
}

.badge-success{
    background-color: rgba(43, 210, 43,0.3);
    color: green;
}
.badge-light{
    background-color: gainsboro;
}
.badge-warning{
    background-color: rgba(255, 165, 0,0.2);
    color: orange;
}

.badge-danger{
    background-color: rgba(255, 0, 0, 0.2);
    color: red;
}

.sml-btn{
    padding: 3px 10px;
    border: none;
    outline: none;
    background-color: #006BC2;
    color: white;
    cursor: pointer;
    font-size: 14px;
}
.sml-btn:focus{
    outline: none;
}
.sm_div{
    border: 1px solid black;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 5px;
}
.sm_div h5{
    font-size: 14px;
    margin-bottom: 0;
}

.sml_btn{
    padding: 7px 15px;
    background-color: grey;
    color: #fff;
    font-size: 14px;
    font-weight:400;
    border: none;
    cursor: pointer;

  }
  .sml_btn:hover{
    color: #fff;
    text-decoration: none;
  }
  .sml_btn:focus{
    outline: none;
  }
.active_btn{
    background-color: #006BC2 !important;
}



table {
    width: 100%;
    border-collapse: collapse; /* Optional: merge table borders*/
}

thead {
    position: sticky;
    top: 0px;
    background-color: #ffffff; /* Optional: add a background color to the thead */

    box-shadow: 0px 2px 2px grey;
    
}

th, td {
    padding: 8px;
    border: 1px solid #ddd; 
}


  .sm_w{
    width: 150px;
  }
  .w_20{
    width: 200px;
  }
  .w_60{
    width: 270px;
    
  }



  .order-track {
    /* margin-top: 2rem; */
    padding: 0 1rem;
    /* border-top: 1px dashed #2c3e50; */
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
  }
  .order-track-step {
    display: flex;
    height: 7rem;
  }
  .order-track-step:last-child {
    overflow: hidden;
    height: 4rem;
  }
  .order-track-step:last-child .order-track-status span:last-of-type {
    display: none;
  }
  .order-track-status {
    margin-right: 1.5rem;
    position: relative;
  }
  .order-track-status-dot {
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #006BC2;
  }
  .order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #006BC2;
  }
  .order-track-text-stat {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 3px;

  }
  .order-track-text-sub {
    font-size: 0.8rem;
    font-weight: 300;
    /* color:#006BC2; */
  }
  
  .order-track {
    transition: all 0.3s height 0.3s;
    transform-origin: top center;
  }

  .sdd_flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  @media (max-width: 500px) {
    .sdd_flex{
      flex-direction:column !important;
    }
  }

 

.form_edit_box .form-group p{
  font-size: 14px ;
  margin: 0px;
  margin-bottom: 5px;
  
}
.form_edit_box .sm_label{
  font-size: 11px !important;
  color: red;
  margin: 0px;
  margin-top: -5px !important;

  margin-bottom: 7px;
}
.form_edit_box .form-control{
  box-shadow: none;
}