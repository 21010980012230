.enroll{
    width: 100%;
    padding: 120px 0 75px 0;
    min-height: 100vh;

}
.enrollbox{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.enroll .form-group p{
    font-size: 14px ;
    margin: 0px;
    margin-bottom: 5px;
    
}
.enroll .sm_label{
    font-size: 11px !important;
    color: red;
    margin: 0px;
    margin-top: -5px !important;
 
    margin-bottom: 7px;
}
.enroll .form-control{
    box-shadow: none;
}
.flexitem{
    display: flex;
    justify-content: end;
    margin-top: 5px;
}
.save_btn{
    /* width: 150px; */
    height: 40px;
    padding: 8px 15px;
    background-color: #006BC2;
    cursor: pointer;
    color: white;
}





@keyframes ldio-3rgqhfk5x79 {
    0% { background: #5699d2 }
    12.5% { background: #5699d2 }
    12.625% { background: #1d3f72 }
    100% { background: #1d3f72 }
}
.ldio-3rgqhfk5x79 div {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #1d3f72;
  animation: ldio-3rgqhfk5x79 1s linear infinite;
}
.loadingio-spinner-blocks-n2or5axwwk {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-3rgqhfk5x79 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-3rgqhfk5x79 div { box-sizing: content-box; }

.preview_box{
    width: 50px;
    height: 50px;
    position: fixed;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    
    background-color: #006BC2;
    z-index: 99999;
    cursor: pointer;
    color: white;
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;

}
.preview_box:hover{
background-color:#318dde;
}
.preview_modal th{
    min-width: 150px !important;
    font-size: 14px !important;
}

.preview_modal td{
    min-width: 200px !important;
    font-size: 14px !important;
}
.profile_previewimg{
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
}