@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.fn_14{
  font-size: 14px;

}
.fn_12{
  font-size: 12px;
}

.fn_18{
  font-size: 18px;
}
.fn_24{
  font-size: 24px;
}
.dis{
  cursor: no-drop;
}
.flex_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.short_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.btn-outline-light{
  color: white ;
}
.btn-outline-light:hover{
  color: black !important;
}
.btn-light{
  background-color: white !important;
}
.cur{
  cursor: pointer;
}
.section_page{
  width: 100%;
  min-height: 100vh;
  padding: 75px 0;
}
.text-col{
  color: #2c3e50;
}
.text-custom{
  color: #006BC2;
}
.title_font{
  font-size: 45px;
}

.title_font_sm{
  font-size: 35px;
  
}
.fa_icon{
  font-size: 20px;
}
.border_card{
  border: 1px solid #2c3e50;
  border-radius: 10px;
  
}
.w-change{
  width: 80%;
}

@media (max-width: 768px) {
  .title_font{
    font-size: 35px;
  }
  .title_font_sm{
    font-size: 28px;
  }
}


.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}



.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pageloading{
  width: 100%;
  height: 100vh;
  background-color: rgba(2, 17, 38, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 999999 !important;
}

.wrong{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.wrong_img{
  width: 250px;
  height: 270px;
  object-fit: contain;
  display: block;
  margin: auto;
}



.model_box{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(85, 99, 206, 0.5);
  z-index: 99999;
}
.inner_model{
  width:350px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}
.inner_model_big{
  width:500px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

.inner_model_sobig{
  width:700px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

.cross{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #006BC2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top:-10px;
}

@media (max-width:650px) {
  .inner_model {
      width: 90%;
  }
  .inner_model_big {
    width: 90%;
}
.w-change{
  width: 95%;
}
  
}



@media (max-width:750px) {
  .inner_model_sobig {
    width: 90%;
}
}

@media (min-width: 767px){
  .modal-lg {
      max-width: 80%;
  }
 
  }



  
.pagination {
  display: flex;
  justify-content: end;
  width: 100%;
  /* padding-top: 50px; */
  color: #000000;
  /* margin: auto; */
}
.pagination a {
  padding: 5px 8px;
  margin: 5px;
  border: 2px solid #006BC2;
  color: black !important;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px !important;
}
.pagination a:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #006BC2;
}
.activebutton a {
  background-color: #006BC2;
  color: #fff !important;
}

#__react-alert__ div div div {
  width: auto !important;
}
.page_what{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background:	#00B100 ;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_what_link a{
  color: #fff;
  font-size: 25px;
}

.css-1k51tf5-MuiTooltip-tooltip {
  background: #000000 !important;
}
.css-kudwh-MuiTooltip-arrow{
  color: #000000 !important;
}

.scroll_bar , .scroll_bar .scroll_bar_content{
  transform: rotateX(180deg);
}

.bg-box{
  display: flex;  
  justify-content: center;
  align-items: center;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #006BC2;
  border-radius: 50%;
  border-color: #ccc #006BC2;
  animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #FED205 #0000;
  animation: inherit; 
  animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@keyframes l16 { 
  100%{transform: rotate(1turn)}
}