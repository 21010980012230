


.textcol{
  color: #0082e6;
}

nav{
    background: #0082e6;
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inn_nav{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }


  .logo{
  
    
    height: 70px !important;
    object-fit: contain;
    
  }

  .inn_nav ul{
    margin-top: 15px;
  }
  nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 2px;
  }
  nav ul li a{
    color: white;
    font-size: 17px;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none ;
  }
  nav ul li a:hover{
    background: #1b9bff;
    transition: .5s;
    text-decoration: none !important;
      color: white !important;
  }
  
  .register_btn{
    background: white !important;
    color: #0082e6 ;
   border-radius: 0;
   
  }
  .register_btn:hover{
    color: #0082e6 !important;
  }
  
 
  nav .bars{
    width: 40px;
    height: 40px;
    
    justify-content: center;
    align-items: center;
   
    background-color: #fff;
    color: #0082e6;
    cursor: pointer;
    display: none;
  }

  .big_nav{
    display: block;
  }
  .sml_nav{
    display: none;
    box-shadow: 0px 4px 10px grey;
  }
  
  
  @media (max-width:1040px) {
    nav .bars {
     display: flex;
    }

    .big_nav{
      display: none;
    }
    
    .sml_nav {
      display: block;
      position: absolute;
      top:60px;
      right: 40px;
      width: 200px;
      background-color: white;
      text-align: center;
      padding: 15px 0;
    
    }
    .sml_nav ul{
      display: inline-block;
    }
    .sml_nav ul li{
      display:block !important;
     margin: -25px  0;
      
    }
    .sml_nav ul li a{
      color:#0082e6 ;
     
    }
    .sml_nav ul li a:hover{
      color: #fff !important;
    }
    .register_btn{
      background:  #0082e6 !important;
      color: white !important;
     border-radius: 0;
    }
    .register_btn:hover{
      color: white !important;
    }
  }


  @media screen and (max-width: 768px){
    .inn_nav{
      width: 98%;
    }
    
  }


  /* @media (min-width: 1000px){
    .inn_nav{
      width: 95%;
    }
  } */

/* 
  nav ul{
    float: right;
    margin-right: 20px;
  }
  nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
  }
  nav ul li a{
    color: white;
    font-size: 17px;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none ;
  }
  a:hover{
    background: #1b9bff;
    transition: .5s;
    text-decoration: none !important;
      color: white !important;
  }
  .checkbtn{
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
  }
  #check{
    display: none;
  }
  @media (max-width: 952px){
    label.logo{
      font-size: 30px;
      padding-left: 50px;
    }
    nav ul li a{
      font-size: 16px;
    }
  }
  @media (max-width: 858px){
    .checkbtn{
      display: block;
    }
   nav ul{
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #2c3e50;
      top: 80px;
      left: -100%;
      text-align: center;
      transition: all .5s;
      
    }
    nav ul li{
      display: block;
      margin: 50px 0;
      line-height: 30px;
    }
    nav ul li a{
      font-size: 20px;
    }
    a:hover,a.active{
      background: none;
      color: #0082e6 ;
      text-decoration: none ;
      
    }
    #check:checked ~ ul{
      left: 0;
    }
    .register_btn{
      background: white !important;
      color: #0082e6 !important;
     
    }
    .register_btn:hover{
      color: #0082e6 !important;
    }
  } */

 

.header {
    /* position: relative; */
    /* text-align: center; */
    
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
}

.inner-header {
    min-height: 80vh;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
   padding-top: 120px;
   padding-bottom: 50px;
}
.inner-header h1{
    font-size: 45px;
    font-weight: 800;
}
.btn{
    padding: 11px 25px !important;
    border-radius: 0 !important;
    letter-spacing: 2px;
    white-space: 4px;

}

@keyframes animate {
    0%{
        transform: translateY(100vh) ;
    }
    100%{
        transform: translateY(-10vh) ;
    }

}

.content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: #fff;
}


.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    min-height: 100px;
    max-height: 100px;
}

.parallax > use {
    -webkit-animation: moveForever 25s cubic-bezier(.55, .5, .45, .5) infinite;
            animation: moveForever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax > use:nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
}

.parallax > use:nth-child(2) {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
}

.parallax > use:nth-child(3) {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    -webkit-animation-duration: 13s;
            animation-duration: 13s;
}

.parallax > use:nth-child(4) {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
}

@-webkit-keyframes moveForever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}

@keyframes moveForever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}

@media (max-width : 768px) {
    h1 {
        font-size: 24px !;
    }
    .content {
        height: 30vh;
    }
    .waves {
        height: 100px;
        min-height: 100px;
    }
}

.heroimg{
    width: 100%;
    height: 350px;
    object-fit: contain;
    filter: drop-shadow(0 0 0.75rem blue);
}

.dflex{
    display: flex;
    
    justify-content: center;
    flex-direction: column;
}
.bubble {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  

}
.bubble span{
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 10px;
  background: #fff;
  /* box-shadow: 0 0 0 5px #e2f3f744 , 0 0 0 5px #fff , 0 0 0 5px #4fc3dc ; */
  animation: animate 15s linear infinite;
  animation-duration: calc(125s / var(--i));
}
/* .bubble span:nth-child(even)
{
  background: red;
  box-shadow: 0 0 0 5px rgb(244, 192, 192);
} */

@keyframes animate {
  0%{
    transform: translateY(80vh) scale(0);
  }
  100%{
    transform: translateY(-10vh) scale(1);
  }
}


.dflex p{
  margin: 20px 0;
}
.d_big{
  display: none ;
}

@media (min-width : 1600px) {
  .d_big{
    display: flex !important;
  }
  .dd_big{
display: none;
  }
}

