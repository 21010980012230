@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.fn_14{
  font-size: 14px;

}
.fn_12{
  font-size: 12px;
}

.fn_18{
  font-size: 18px;
}
.fn_24{
  font-size: 24px;
}
.dis{
  cursor: no-drop;
}
.flex_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.short_p{
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.btn-outline-light{
  color: white ;
}
.btn-outline-light:hover{
  color: black !important;
}
.btn-light{
  background-color: white !important;
}
.cur{
  cursor: pointer;
}
.section_page{
  width: 100%;
  min-height: 100vh;
  padding: 75px 0;
}
.text-col{
  color: #2c3e50;
}
.text-custom{
  color: #006BC2;
}
.title_font{
  font-size: 45px;
}

.title_font_sm{
  font-size: 35px;
  
}
.fa_icon{
  font-size: 20px;
}
.border_card{
  border: 1px solid #2c3e50;
  border-radius: 10px;
  
}
.w-change{
  width: 80%;
}

@media (max-width: 768px) {
  .title_font{
    font-size: 35px;
  }
  .title_font_sm{
    font-size: 28px;
  }
}


.error {
  width: 100%;
  display: grid;
  place-items: center;
  background-color: ghostwhite;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.error h1 {
  font-size: 60px;
  font-weight: 700;
  text-shadow: 0px 0px 5px rgb(226, 131, 147);
}



.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.pageloading{
  width: 100%;
  height: 100vh;
  background-color: rgba(2, 17, 38, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 999999 !important;
}

.wrong{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.wrong_img{
  width: 250px;
  height: 270px;
  object-fit: contain;
  display: block;
  margin: auto;
}



.model_box{
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(85, 99, 206, 0.5);
  z-index: 99999;
}
.inner_model{
  width:350px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}
.inner_model_big{
  width:500px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

.inner_model_sobig{
  width:700px;
  background-color: white;
  height: auto;
  padding: 15px;
  position: relative;
}

.cross{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #006BC2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: -10px;
  top:-10px;
}

@media (max-width:650px) {
  .inner_model {
      width: 90%;
  }
  .inner_model_big {
    width: 90%;
}
.w-change{
  width: 95%;
}
  
}



@media (max-width:750px) {
  .inner_model_sobig {
    width: 90%;
}
}

@media (min-width: 767px){
  .modal-lg {
      max-width: 80%;
  }
 
  }



  
.pagination {
  display: flex;
  justify-content: end;
  width: 100%;
  /* padding-top: 50px; */
  color: #000000;
  /* margin: auto; */
}
.pagination a {
  padding: 5px 8px;
  margin: 5px;
  border: 2px solid #006BC2;
  color: black !important;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px !important;
}
.pagination a:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #006BC2;
}
.activebutton a {
  background-color: #006BC2;
  color: #fff !important;
}

#__react-alert__ div div div {
  width: auto !important;
}
.page_what{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background:	#00B100 ;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page_what_link a{
  color: #fff;
  font-size: 25px;
}

.css-1k51tf5-MuiTooltip-tooltip {
  background: #000000 !important;
}
.css-kudwh-MuiTooltip-arrow{
  color: #000000 !important;
}

.scroll_bar , .scroll_bar .scroll_bar_content{
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}

.bg-box{
  display: flex;  
  justify-content: center;
  align-items: center;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #006BC2;
  border-radius: 50%;
  border-color: #ccc #006BC2;
  -webkit-animation: l16 1s infinite linear;
          animation: l16 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader::before {
  border-color: #FED205 #0000;
  -webkit-animation: inherit;
          animation: inherit; 
  -webkit-animation-duration: .5s; 
          animation-duration: .5s;
  animation-direction: reverse;
}
.loader::after {
  margin: 8px;
}
@-webkit-keyframes l16 { 
  100%{-webkit-transform: rotate(1turn);transform: rotate(1turn)}
}
@keyframes l16 { 
  100%{-webkit-transform: rotate(1turn);transform: rotate(1turn)}
}

.courseimg{
  width: 100%;
  height: 250px;
  object-fit: contain;
  border-radius: 10px;
  /* filter: drop-shadow(0 0 2px grey); */
}
.div_box{
  width: 100%;
  height: 250px;
  background-color: rgba(128, 128, 128,0.5) !important;
  border-radius: 10px;
 
}

.card{
  border: none;
  border-radius: 0;
  box-shadow: 0px 2px 4px grey;
}
.hero_img_box{
  position: relative;
}
.hero_img_box_inner{
  width: 250px;
  height: 100px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  -webkit-animation: updown 3s linear infinite  alternate;
          animation: updown 3s linear infinite  alternate;
}

.hero_img_box_inner_right{
  width: 200px;
  height: 80px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  right: -25px;
  top: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  -webkit-animation: updown 4s linear infinite  alternate;
          animation: updown 4s linear infinite  alternate;
}
.access_hero_img_box_inner_left{
  width: 200px;
  height: 80px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  left:25px;
  bottom: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  -webkit-animation: updown 4s linear infinite  alternate;
          animation: updown 4s linear infinite  alternate;
}

.access_hero_img_box_inner_right_top{
  width: 200px;
  height: 80px;
  box-shadow: 0px 2px 4px grey;
  position: absolute;
  left:250px;
 
  top: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  -webkit-animation: updown 4s linear infinite  alternate;
          animation: updown 4s linear infinite  alternate;
}

.hero_img_box_inner .avtar{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover !important;
}




@-webkit-keyframes updown {
  0%   {
    -webkit-transform: translateY(-45%);
            transform: translateY(-45%);
  }
  50%  {-webkit-transform: translateY(0%);transform: translateY(0%);}
  
  100% {-webkit-transform: translateY(45%);transform: translateY(45%);}
}




@keyframes updown {
  0%   {
    -webkit-transform: translateY(-45%);
            transform: translateY(-45%);
  }
  50%  {-webkit-transform: translateY(0%);transform: translateY(0%);}
  
  100% {-webkit-transform: translateY(45%);transform: translateY(45%);}
}




@media (min-width: 768px) and (max-width: 992px){
  .hero_img_box_inner_right{
    width: 150px !important;
    right: -20px;
    
  }
 .inner-header .dflex h1 {
font-size: 35px !important;
  }
  
}
  
@media (max-width: 500px){
  .hero_img_box_inner_right{
    display: none;
  }
  .hero_img_box_inner{
    left: 15px;
  }
  .access_hero_img_box_inner_right_top{
    left:100px;
  }
}







.company{
    width: 100%;
    padding: 45px 0;

}
.com_slider{
    width: 80%;
  
    margin: auto;
    overflow: hidden;
    position: relative;
    display: block;
   
}
.slider_track{
display: flex;
width: calc(150px * 10);

-webkit-animation: scroll 40s linear infinite  ;

        animation: scroll 40s linear infinite  ;

}

.com_slider .slide_com{
    height: 100px;
    width: 150px;
    margin: 15px;
}
.slide_com img{
  width: 120px;
  height: 120px;
  object-fit: contain;
}

@-webkit-keyframes scroll{
    0%{
-webkit-transform: translatXe(0);
        transform: translatXe(0);
    }
    100%{
        -webkit-transform: translateX(calc( -150px * 5));
                transform: translateX(calc( -150px * 5));
    }
}

@keyframes scroll{
    0%{
-webkit-transform: translatXe(0);
        transform: translatXe(0);
    }
    100%{
        -webkit-transform: translateX(calc( -150px * 5));
                transform: translateX(calc( -150px * 5));
    }
} 

.span_dis{
    -webkit-text-decoration-color: #0082e6 !important;
            text-decoration-color: #0082e6 !important;
 text-decoration: underline;
}


.home_flex{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    gap: 10px;
}

.stu_flex{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    gap: 10px;
}

.box_item{
    width: 100%;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid #006BC2;
}
.box_item h1{
    color: #006BC2;
    font-weight: 800;
    font-size: 60px;

}
.company .card{
    border: none;
    border-radius: 0;
    box-shadow: 0px 2px 4px grey;
    border-bottom: 4px solid #0082e6;
}
.company .card h1{
    color: #006BC2;
}

.about{
    width: 100%;
    padding: 45px 0;
    position: relative;
  }
  .about::after {
    position: absolute;
    content: '';
    top: 0px;
    right: 0;
  
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url('https://www.mm2h.com/wp-content/uploads/2019/12/dark-blue-blob.png');
  
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 30%;
  }
  .aboutimg{
    width: 100%;
    height: 450px;
    object-fit: contain;
  }
  .about h2{
    font-size: 40px;
    font-weight: 800;
  }
  .about p{
    margin: 15px 0;
    color: #2c3e50;
    font-size: 20px;
  }
  .about ul{
    list-style-type: none;
  }
  .about ul li{
    
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
  
  }
  .about ul li i{
    margin-right: 15px;
    color: #0082e6;
    font-size: 25px;
  }

 .center_div{
    width: 90%;
    display: block;
    margin: auto;
  
 }

 .aboutsec{
    width: 100%;
    padding: 100px 0px 35px 0;
    background-color: #006BC2;
 }
 .aboutsec .card{
    border-radius: 0;
 }

 .card_im img{
    width: 100%;
    height: 450px;
    object-fit: contain;
    margin-top: -60px;
 }
 .card_im h1{
    font-size: 45px;
    font-weight: 700;
    color: #006BC2;
 }
 /* .dd_flex{
    display: flex;
    justify-content: center;
    flex-direction: column;
 } */

 @media (min-width:760px) and (max-width:900px) {
    .card_im img{
        margin: 0;
    }
 }

 .studentlist{
    width: 100%;
    padding: 50px 0;
 }

 .student_item{
    width: 100%;
    height: 250px;
    background-color: #006BC2;
    padding: 15px;
    border-radius: 5px;
    color: white;
    text-align: center;

 }
 .stu_img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
    margin: auto;
    background-color: #006BC2;
    border: 7px solid white;
 }
 .student_item p{
  margin-top:-10px ;
 }
 .com_img{
    width: 100px;
    height: 70px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-top: -20px;
 }



 .afterhero{
  width: 100%;
  min-height: 50vh;
  padding: 75px 0;

 }
 .afterhero h1 {
  text-align: center;
  font-size: 35px;
  color: #3F3A64;
  font-weight: 600;
 }
 .afterhero h3{
  text-align: center;
  font-size: 30px;
  color: #3F3A64;
  font-weight: 600;
 }
 .icon_img{
  width:60px;
  height: 60px;
  display: block;
  margin: auto;
  transition: all 0.5s;
 }
 .afterhero_card{
  transition: all 0.5s;
 }
 .afterhero_card h5{
  margin: 15px 0;
 color: #032542;
 }
 .afterhero_card:hover .icon_img{
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
 }
 .afterhero_card:hover h5{
  color: #006BC2;
 }

 .access_img{
  width: 100%;
  height: 600px;
  border-radius: 15px;
 }
 .access_box{
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  
  
 }
 .access_box h1{
  width: 80%;
  text-align: left;
  color: #3F3A64;
  font-weight: 600;
 }
 .access_box p{
  width: 90%;
 }
 .acces_flex_items{
 margin-top: 15px;
  width: 90%;
 }
 .acces_flex_item img{
  width: 75px ;
  height: 75px;
  border-radius:15px;
  object-fit: contain;
 background-color: #F7F5F4;
 padding: 10px;
 margin-right: 25px;
 }
 .acces_flex_item{
  display: flex;
 margin: 15px 0;
  
 }
 .acces_flex_item div h5{
  color: #032542;
 }
 .acces_flex_item div p{
  color: grey;
 }
 .access_box ul{
  margin-left: 15px;
 }
 .access_box ul li{
  margin: 15px 0;
 }
.btn-warning{
  background-color: #FED205 !important;
  border: none;
 }
 .access_rel{
  position: relative;
 }


 @media (max-width:992px) {
  
  .access_box h1{
    width: 100%;
  }
  .access_box p{
    width: 100%;
  }
  
 }

 @media (max-width:768px) {
  
  
  .acces_flex_items{
    width: 100%;
  }

  .access_img{
    height: 450px;
   }
  
 }




 .big_text {
  background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: 900;
  margin-top: 15px;
}

.big_text p {
  font-size: 8vw;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) or (-webkit-background-clip: text) {
  .fancy {
    background-image: url("data:image/svg+xml,%3Csvg width='2250' height='900'  fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='%2300A080' d='M0 0h2255v899H0z'/%3E%3Ccircle cx='366' cy='207' r='366' fill='%2300FDCF'/%3E%3Ccircle cx='1777.5' cy='318.5' r='477.5' fill='%2300FDCF'/%3E%3Ccircle cx='1215' cy='737' r='366' fill='%23008060'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-size: 110% auto;
    background-position: center;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

@media (max-width: 768px) {
  .big_text p{
    font-size: 65px;
  }
  
}

@media (max-width: 550px) {
  .big_text{
    min-height: 30vh;
  }
  .big_text p{
    font-size:50px;
  }
  
}

@media (max-width: 400px) {
  .big_text{
    min-height: 30vh;
  }
  .big_text p{
    font-size:40px;
  }
  
}



.textcol{
  color: #0082e6;
}

nav{
    background: #0082e6;
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inn_nav{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }


  .logo{
  
    
    height: 70px !important;
    object-fit: contain;
    
  }

  .inn_nav ul{
    margin-top: 15px;
  }
  nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 2px;
  }
  nav ul li a{
    color: white;
    font-size: 17px;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none ;
  }
  nav ul li a:hover{
    background: #1b9bff;
    transition: .5s;
    text-decoration: none !important;
      color: white !important;
  }
  
  .register_btn{
    background: white !important;
    color: #0082e6 ;
   border-radius: 0;
   
  }
  .register_btn:hover{
    color: #0082e6 !important;
  }
  
 
  nav .bars{
    width: 40px;
    height: 40px;
    
    justify-content: center;
    align-items: center;
   
    background-color: #fff;
    color: #0082e6;
    cursor: pointer;
    display: none;
  }

  .big_nav{
    display: block;
  }
  .sml_nav{
    display: none;
    box-shadow: 0px 4px 10px grey;
  }
  
  
  @media (max-width:1040px) {
    nav .bars {
     display: flex;
    }

    .big_nav{
      display: none;
    }
    
    .sml_nav {
      display: block;
      position: absolute;
      top:60px;
      right: 40px;
      width: 200px;
      background-color: white;
      text-align: center;
      padding: 15px 0;
    
    }
    .sml_nav ul{
      display: inline-block;
    }
    .sml_nav ul li{
      display:block !important;
     margin: -25px  0;
      
    }
    .sml_nav ul li a{
      color:#0082e6 ;
     
    }
    .sml_nav ul li a:hover{
      color: #fff !important;
    }
    .register_btn{
      background:  #0082e6 !important;
      color: white !important;
     border-radius: 0;
    }
    .register_btn:hover{
      color: white !important;
    }
  }


  @media screen and (max-width: 768px){
    .inn_nav{
      width: 98%;
    }
    
  }


  /* @media (min-width: 1000px){
    .inn_nav{
      width: 95%;
    }
  } */

/* 
  nav ul{
    float: right;
    margin-right: 20px;
  }
  nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
  }
  nav ul li a{
    color: white;
    font-size: 17px;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none ;
  }
  a:hover{
    background: #1b9bff;
    transition: .5s;
    text-decoration: none !important;
      color: white !important;
  }
  .checkbtn{
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
  }
  #check{
    display: none;
  }
  @media (max-width: 952px){
    label.logo{
      font-size: 30px;
      padding-left: 50px;
    }
    nav ul li a{
      font-size: 16px;
    }
  }
  @media (max-width: 858px){
    .checkbtn{
      display: block;
    }
   nav ul{
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #2c3e50;
      top: 80px;
      left: -100%;
      text-align: center;
      transition: all .5s;
      
    }
    nav ul li{
      display: block;
      margin: 50px 0;
      line-height: 30px;
    }
    nav ul li a{
      font-size: 20px;
    }
    a:hover,a.active{
      background: none;
      color: #0082e6 ;
      text-decoration: none ;
      
    }
    #check:checked ~ ul{
      left: 0;
    }
    .register_btn{
      background: white !important;
      color: #0082e6 !important;
     
    }
    .register_btn:hover{
      color: #0082e6 !important;
    }
  } */

 

.header {
    /* position: relative; */
    /* text-align: center; */
    
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
}

.inner-header {
    min-height: 80vh;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
   padding-top: 120px;
   padding-bottom: 50px;
}
.inner-header h1{
    font-size: 45px;
    font-weight: 800;
}
.btn{
    padding: 11px 25px !important;
    border-radius: 0 !important;
    letter-spacing: 2px;
    white-space: 4px;

}

@-webkit-keyframes animate {
    0%{
        -webkit-transform: translateY(100vh) ;
                transform: translateY(100vh) ;
    }
    100%{
        -webkit-transform: translateY(-10vh) ;
                transform: translateY(-10vh) ;
    }

}

@keyframes animate {
    0%{
        -webkit-transform: translateY(100vh) ;
                transform: translateY(100vh) ;
    }
    100%{
        -webkit-transform: translateY(-10vh) ;
                transform: translateY(-10vh) ;
    }

}

.content {
    position: relative;
    height: 20vh;
    text-align: center;
    background-color: #fff;
}


.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    min-height: 100px;
    max-height: 100px;
}

.parallax > use {
    -webkit-animation: moveForever 25s cubic-bezier(.55, .5, .45, .5) infinite;
            animation: moveForever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax > use:nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
}

.parallax > use:nth-child(2) {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
}

.parallax > use:nth-child(3) {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    -webkit-animation-duration: 13s;
            animation-duration: 13s;
}

.parallax > use:nth-child(4) {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
}

@-webkit-keyframes moveForever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}

@keyframes moveForever {
    0% {
        -webkit-transform: translate3d(-90px, 0, 0);
                transform: translate3d(-90px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(85px, 0, 0);
                transform: translate3d(85px, 0, 0);
    }
}

@media (max-width : 768px) {
    h1 {
        font-size: 24px !;
    }
    .content {
        height: 30vh;
    }
    .waves {
        height: 100px;
        min-height: 100px;
    }
}

.heroimg{
    width: 100%;
    height: 350px;
    object-fit: contain;
    -webkit-filter: drop-shadow(0 0 0.75rem blue);
            filter: drop-shadow(0 0 0.75rem blue);
}

.dflex{
    display: flex;
    
    justify-content: center;
    flex-direction: column;
}
.bubble {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  

}
.bubble span{
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 10px;
  background: #fff;
  /* box-shadow: 0 0 0 5px #e2f3f744 , 0 0 0 5px #fff , 0 0 0 5px #4fc3dc ; */
  -webkit-animation: animate 15s linear infinite;
          animation: animate 15s linear infinite;
  -webkit-animation-duration: calc(125s / var(--i));
          animation-duration: calc(125s / var(--i));
}
/* .bubble span:nth-child(even)
{
  background: red;
  box-shadow: 0 0 0 5px rgb(244, 192, 192);
} */

@keyframes animate {
  0%{
    -webkit-transform: translateY(80vh) scale(0);
            transform: translateY(80vh) scale(0);
  }
  100%{
    -webkit-transform: translateY(-10vh) scale(1);
            transform: translateY(-10vh) scale(1);
  }
}


.dflex p{
  margin: 20px 0;
}
.d_big{
  display: none ;
}

@media (min-width : 1600px) {
  .d_big{
    display: flex !important;
  }
  .dd_big{
display: none;
  }
}



footer{
    /* position: fixed; */
    background:ghostwhite;
    /* background: linear-gradient(rgba(1, 53, 65,0.5),rgba(1, 53, 65,0.5)) ,url('../image/hero-bg.png'); */
    /* background-color: #233565; */
   
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 50vh;
    bottom: 0;
    left: 0;
    position: relative;
    
  }

  .bg-blue{
    background-color:rgba(50, 25, 237, 0.2);
  }
  .fot_img{
    width: 120px;
    height: 40px;
    object-fit: contain;
    margin-top: -5px;
  }

  footer ul li a:hover{
background: transparent !important;
color:#140B5C !important;
  }
  footer a:hover{
    background: transparent !important;
color:#140B5C !important;
  }
  
  footer::before{
    content: '';
    position: absolute;
    left: 0;
    top: 100px;
    height: 1px;
    width: 100%;
    background: #AFAFB6;
  }
  footer .fot_content{
    max-width: 1250px;
    margin: auto;
    padding: 30px ;
  }
  footer .fot_content .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    
    
  }
  .fot_content .top .logo-details span{
    color:#140B5C !important;
    font-size: 30px;
  }
  .fot_content .top .logo-details p{
    font-size: 16px;
  }
  .fot_content .top .media-icons{
    display: flex;
  }
  .fot_content .top .media-icons a{
    height: 40px;
    width: 40px;
    margin: 0 8px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color:#140B5C !important;
    font-size: 17px;
    text-decoration: none;
    transition: all 0.4s ease;
  }
  .top .media-icons a:nth-child(1){
    background: #4267B2;
    color:white !important;
  }
  .top .media-icons a:nth-child(1):hover{
    color: #4267B2 !important;
    border:1px solid #4267B2;
  }
  .top .media-icons a:nth-child(2){
    background: #1DA1F2;
    color:white !important;
  }
  .top .media-icons a:nth-child(2):hover{
    color: #1DA1F2 !important;
    border:1px solid #1DA1F2;
  }
  .top .media-icons a:nth-child(3){
    background: #E1306C;
    color:white !important;
  }
  .top .media-icons a:nth-child(3):hover{
    color: #E1306C !important;
    border:1px solid #E1306C;
  }
  .top .media-icons a:nth-child(4){
    background: #0077B5;
    color:white !important;
  }
  .top .media-icons a:nth-child(4):hover{
    color: #0077B5 !important;
    border:1px solid #0077B5;
  }
  .top .media-icons a:nth-child(5){
    color:white !important;
    background: #FF0000;
  }
  .top .media-icons a:nth-child(5):hover{
    color: #FF0000 !important;
    border:1px solid #FF0000;
  }
  footer .fot_content .link-boxes{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  footer .fot_content .link-boxes .box{
    width: calc(100% / 5 - 10px);
  }
  footer .fot_content .link-boxes .box .link_name{
    color:#140B5C !important;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    position: relative;
  }
  footer .link-boxes .box .link_name::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 2px;
    width: 35px;
    background: #140B5C;
  }
  footer .fot_content .link-boxes .box li{
    margin: 6px 0;
    list-style: none;
  }
  footer .fot_content .link-boxes .box li a{
    color:#140B5C !important;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.4s ease
  }
  footer .fot_content .link-boxes .box li a:hover{
    opacity: 1;
    text-decoration: underline;
  }
  footer .fot_content .link-boxes .input-box{
    margin-right: 55px;
  }
  footer .link-boxes .input-box input{
    height: 40px;
    width: calc(100% + 55px);
    outline: none;
    border: 2px solid #AFAFB6;
    background: transparent;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    color:#140B5C !important;
    margin-top: 5px;
  }
  footer .link-boxes .input-box input::-webkit-input-placeholder{
    color: #AFAFB6;
    font-size: 16px;
  }
  footer .link-boxes .input-box input::placeholder{
    color: #AFAFB6;
    font-size: 16px;
  }
  footer .link-boxes .input-box input[type="button"]{
    background: #fff;
    color: #140B5C;
    border: none;
    font-size: 18px;
    font-weight: 500;
    margin: 4px 0;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.4s ease;
  }
  footer .input-box input[type="button"]:hover{
    opacity: 1;
  }
  footer .bottom-details{
    width: 100%;
    background: orange;
  }
  footer .bottom-details .bottom_text{
    max-width: 1250px;
    margin: auto;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
  }
  footer .bottom-details .bottom_text span,
  footer .bottom-details .bottom_text a{
    font-size: 14px;
    font-weight: 300;
    color:#140B5C !important;
    opacity: 0.8;
    text-decoration: none;
  }
  footer .bottom-details .bottom_text a:hover{
    opacity: 1;
    text-decoration: underline;
  }
  footer .bottom-details .bottom_text a{
    margin-right: 10px;
  }
  @media (max-width: 900px) {
    footer .fot_content .link-boxes{
      flex-wrap: wrap;
    }
    footer .fot_content .link-boxes .input-box{
      width: 40%;
      margin-top: 10px;
    }
  }
  @media (max-width: 700px){
    footer{
      position: relative;
    }
    footer .fot_content .top .logo-details{
      font-size: 26px;
    }
    footer .fot_content .top .media-icons a{
      height: 35px;
      width: 35px;
      font-size: 14px;
      line-height: 35px;
    }
    footer .fot_content .link-boxes .box{
      width: calc(100% / 3 - 10px);
    }
    footer .fot_content .link-boxes .input-box{
      width: 60%;
    }
    footer .bottom-details .bottom_text span,
    footer .bottom-details .bottom_text a{
      font-size: 12px;
    }
  }
  @media (max-width: 520px){
    footer::before{
      top: 145px;
    }
    footer .fot_content .top{
      flex-direction: column;
    }
    footer .fot_content .top .media-icons{
      margin-top: 16px;
    }
    footer .fot_content .link-boxes .box{
      width: calc(100% / 2 - 10px);
    }
    footer .fot_content .link-boxes .input-box{
      width: 100%;
    }
  }
  

  @media (max-width: 858px){
    footer ul li a{
        color: white !important;
    }
    footer ul li a:hover{
        background: transparent !important;
        color: white !important;
          }
          footer a:hover{
            background: transparent !important;
        color: white !important;
          }
  }
.auth{
    width: 100%;
    min-height: 100vh;
    padding: 100px 0 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("https://edushark.in/image/loginhome.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.auth_img{
    width: 100%;
    /* height: 450px; */
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.auth_box input , .auth_box select {
    width: 100%;
    height: 43px;
    border: 1px solid #006BC2;
    padding: 0px 10px;
    /* margin-bottom: 15px; */
}
.input_btn{
    height: 43px;
    padding: 5px 10px;
    border:none;
    outline: none;
    background-color: #006BC2;
    color:white;
    font-size: 12px ;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
.input_btn:focus{
    outline: none;
}
.auth_box input:focus , .auth_box select:focus{
    outline: none;
}

@media (max-width: 767px) {
    .auth_img{
        width: 150px;
        height: 150px;
        display: block;
        margin: 15px auto;
        border-radius: 50%;
    }
    
}

.otpinput{
    width: 80%;
    height: 43px;
    border: 1px solid #006BC2;
    padding-left: 10px;
    display: block;
    margin: auto;
}
.otpinput:focus{
    outline: none;
}
.success_img{
    width: 150px;
    height: 150px;
    display: block;
    margin: auto;
    object-fit: contain;
  }
  .input_btn_green{
    height: 43px;
    padding: 5px 10px;
    border:none;
    outline: none;
    background-color: #04a930;
    color:white;
    /* font-size: 12px; */
    display: flex;
    justify-content: center;
    align-items: center;
   

  }

  .formh{
    height: 43px;
  }

 .re_p{
    margin-top: -12px !important;
    color: #006BC2;
 }

.alertw{
    width: 80%;
    display: block;
    margin: auto;
}
 .countdown-text {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:10px auto ;
  }
  
  /* Countdown text paragraph styles */
  .countdown-text > p {
    font-size: 13px;
    font-weight: 400;
    color: #2d1d35;
  }
  
  /* Countdown text button styles */
  .countdown-text > button {
    background-color: #fff;
    border: none;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    margin-top: -20px;
  }

  @media (max-width:776px) {
    .otpinput{
        width: 90%;
    }
    .countdown-text{
        width: 90%;
    }
    .alertw{
        width: 90%;
    }
  }

  .otpbox{
    width: 100%;
    min-height:100vh;
    padding: 100px 0;
  }
  .optimg{
    width: 170px;
    height: 120px;
    margin: auto;
    display: block;
    object-fit: contain;
  }
  
  .otpform label{
    margin-bottom: -5px;
  }
.det_card{
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
    
}
.section_page ul{
    /* list-style-type: none; */
    margin-left: 15px;
}
.section_page ul li{
    margin: 15px 0;
}
.section_page ul li a{
    color:#2c3e50;
    
}
.section_page ul li a:hover{
    color:#2c3e50 !important;
    background: none !important;
    text-decoration: underline !important;
}
.cur_Det_img{
    width: 100%;
    height: 500px;
    object-fit: contain;
    border-radius: 10px;
}
.img_rel{
    position: relative;
    
}
.img_abs{
    position: absolute;
    width: 100%;
    height: 100%;
   background-color: rgba(0, 0, 0,0.4);
   border-radius: 10px;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}
.play_btn{
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}



.play_btn::before {
    content: "";
    position: absolute;
    /* top: -37%;
    right: -20px; */

    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 50%;
    -webkit-animation: ph 1s linear infinite;
            animation: ph 1s linear infinite;
}

.play_btn::after {
    content: "";
    position: absolute;
    /* top: -37%;
    right: -20px; */

    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 50%;
    /* animation: ph 1s linear infinite; */
    -webkit-animation: ph 1s linear infinite;
            animation: ph 1s linear infinite;
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
}

@-webkit-keyframes ph {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes ph {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
        opacity: 0;
    }

    50% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.5);
                transform: scale(1.5);
        opacity: 0;
    }
}


.video_prev{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0,0.4);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}


.video_prev_box{
    width: 650px;
    height: 400px;
    position: relative;
    background-color: black;
    border-radius: 15px;
}
.cross_b{
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;

}

.text-sml{
    font-size: 40px;
}
@media (max-width: 768px) {
    .video_prev_box{
        width: 90%;
    }
    
    
}

@media (max-width:550px) {
    .text-sml{
        font-size: 30px;
    }
}

@media (max-width:400px) {
    .text-sml{
        font-size: 27px;
    }
}


.other_tab table {
    border-collapse: collapse !important;
  }

  @media screen and (max-width: 800px) {
    .other_tab table{
      border: 0;
    }

    .other_tab table th, .other_tab table td {
      border: 0;
      display: block;
      width: 100%;
      box-sizing: border-box;
    }


  }
 
  
  .other_tab th, .other_tab td {
    border: 1px solid black;
  }
  .text-size{
    font-size: 25px !important;
  }
.clg_img{
    width: 100%;
    height: 280px;
    border-radius: 10px;
}


.purches_img{
    width: 100%;
    height:300px;
    object-fit: cover;
    display: block;
    margin: auto;
    border-radius: 10px;

}
.book_img{
    width: 100%;
    height: 320px;
    object-fit: contain;
    border-radius: 5px;
    display: block;
    margin: auto;

}
.flex-end{
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}
.display_flex{
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    
}
.form_h{
    height: 45px !important;
}
.word_r{
    word-wrap: break-word !important;
}



.carousel-control-prev-icon{
    background-color: blue;
    width: 20px  ;
    height: 45px;
    /* background-image:url('https://res.cloudinary.com/dlib0cjnl/image/upload/v1699759392/icons8-chevron-left-30_xavenl.png') */
}

.carousel-control-next-icon{
    background-color: blue;
    width: 20px  ;
    height: 45px;
   
}

.book_card{
    position: relative;
}
.book_card_abs{
    position: absolute;
    top:10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ghostwhite;
    box-shadow: 0px 0px 10px grey;
    display: flex;
    justify-content: center;
    align-items: center;
}




.dropdown {
    position: relative;
    display: inline-block;
    
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right:0;
    background-color:white;
    min-width: 220px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   
  }
  
  .dropdown-content a {
    
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .dropdown-content a:hover {
    background-color: rgba(221, 221, 221,0.5);
   
}
  
  .dropdown:hover .dropdown-content {display: block;}

  .share-icon{
    font-size: 12px;
  }

  .text_fb{
    color:#4064AC !important;
  }
  
  .text_wh{
    color:#54CF62 !important;
  }
  .text_tw{
    color:#1DA1F2 !important;
  }
 .book_cover{
  width: 100%;
 display: block;
 margin: auto;
 background-color: ghostwhite;
 border-radius: 10px;
 padding: 10px 5px;
 position: relative;
 }
 .book_cover img{
  width: 100%;
    height: 450px;
    object-fit: contain;
    border-radius: 5px;
    display: block;
    margin: auto;
 }


.form-control {
  border:1px solid #006BC2 !important;
  
}
 .form-control:focus{
  
box-shadow: none;
  
}
.img_text{
    
    position: relative;
    padding: 5px;
}
.img_text_page{
    position: absolute;
    left: 0;
    bottom: 0;
    width:calc(100% - 10px);
    height: auto;
    background-color: rgba(0,0,0,0.5);
    margin-left: 5px;
    margin-bottom: 5px;
    color: white;
    font-size: 16px;
    text-align: center;
    padding: 5px;
}
.contact{
    width: 100%;
    min-height: 120vh;
    padding: 100px 0px 75px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact_input input {
    height: 43px;
    border: 1px solid #006BC2;
    
}
.contact_input textarea{
    border: 1px solid #006BC2;
}
.contact_input input:focus , .contact_input textarea:focus{
    outline: none;
    box-shadow: none;
    
}
.top__rate{
  width: 100%;
  height: auto;
  
  padding: 25px 0;
}



.top__rate{
  /* width: 100px;
  height: 150px; */
  position: relative;
 
  
}
.top__rate .card_img{
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
 
  

}
.top_card_link{
  border: 1px solid rgba(168, 166, 166,0.5);
  border-radius: 5px;
}
.top_card_link a:hover{
  color:black;
  text-decoration: none;
  
}
.top_p{
margin: 0;
display: -webkit-box;
overflow: hidden;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;

}


.dd_flex{
display: flex;
justify-content: space-between;
align-items: center;
}

.dd_flex a{
font-size: 30px;
color: #B8232B;
}
.d_flex{
display: flex;

}











.top_rate_nav_link:hover a{
  display: block;
}






.top__rate .swiper-slide
{
  width: 14%;
  transition: all 0.5s;
  
}
.link_card{
color:black;
}
.link_card:hover{
color: #000;
text-decoration: none;
}


.ads_img{
width: 100%;
height: 150px ;
border-radius: 5px;
/* object-fit: ; */
}

@media  (min-width:1000px) {
.top__rate .swiper-slide:hover{
  width: 20%;
  box-shadow: 0px 4px 7px grey;
}
}
@media  (min-width:1200px) {
.top__rate .swiper-slide:hover{
  width: 16%;
  box-shadow: 0px 4px 7px grey;
}
}

@media (max-width:1200px) {
.top__rate .swiper-slide
{
  width: 18%;
}


}


@media (max-width:1000px) {
  .top__rate .swiper-slide
  {
    width: 20%;
  }
  
}

@media (max-width:800px) {
  .top__rate .swiper-slide
  {
    width: 25%;
  }
  
}

@media (max-width:550px) {
  .top__rate .swiper-slide
  {
    width: 35%;
  }
  
}
@media (max-width:450px) {
  .top__rate .swiper-slide
  {
    width: 45%;
  }
  
}



.banar{
  width: 98%;
  height: auto;
 display: block;
 margin: auto;
 box-shadow: 0px 2px 4px grey;
 margin-top: 10px;
}

.banar .swiper-slide
{
  width: 50%;
}

.banar__slider img{
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.banar_link{
  position: relative;
  width: 100%;
  height: 100%;
}




.swiper-button-prev
{

color: #006BC2 !important;
position: absolute;
left:-2px !important ;
top: 130px !important;
height: 100px !important;
 
background-color: white;

}

.swiper-button-next
{

color: #006BC2 !important;
position: absolute;
right:-2px !important ;
top: 130px !important;
height: 100px !important;
 
background-color: white;

}



@media (max-width:900px) {
 
.banar .swiper-slide
{
  width: 100%;
}
.banar__slider img{
  
  height: 250px;
 
}

.swiper-button-prev
{


top: 125px !important;
height: 50px !important;
 
}
.swiper-button-next
{


top: 125px !important;
height: 50px !important;
 


}
}









.abo_img{
    width:100%;
   object-fit: contain;
   border-radius: 10px;
}
.stu_home{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top:0;
    left: 0;
    background-color: white;
    z-index: 999999;
}
.form_height{
    height: 42px;
}
.stu_gif{
width: 80%;
/* height: 350px; */
display: block;
margin: auto;
}

.welcome_dash{
    width: 100%;
    height: 200px;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: #fff;
    border-radius: 10px;
    display: flex;
   justify-content: center;
    flex-direction: column;
    position: relative;
}
.left_stu_dash{
    position: absolute;
    left: 0;
    z-index: 2;
    

}
.right_stu_dash{
    position: absolute;
    right: 0;
    z-index: 1;
}
.right_stu_dash img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding: 15px;
}

.badge-success{
    background-color: rgba(43, 210, 43,0.3);
    color: green;
}
.badge-light{
    background-color: gainsboro;
}
.badge-warning{
    background-color: rgba(255, 165, 0,0.2);
    color: orange;
}

.badge-danger{
    background-color: rgba(255, 0, 0, 0.2);
    color: red;
}

.sml-btn{
    padding: 3px 10px;
    border: none;
    outline: none;
    background-color: #006BC2;
    color: white;
    cursor: pointer;
    font-size: 14px;
}
.sml-btn:focus{
    outline: none;
}
.sm_div{
    border: 1px solid black;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 5px;
}
.sm_div h5{
    font-size: 14px;
    margin-bottom: 0;
}

.sml_btn{
    padding: 7px 15px;
    background-color: grey;
    color: #fff;
    font-size: 14px;
    font-weight:400;
    border: none;
    cursor: pointer;

  }
  .sml_btn:hover{
    color: #fff;
    text-decoration: none;
  }
  .sml_btn:focus{
    outline: none;
  }
.active_btn{
    background-color: #006BC2 !important;
}



table {
    width: 100%;
    border-collapse: collapse; /* Optional: merge table borders*/
}

thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background-color: #ffffff; /* Optional: add a background color to the thead */

    box-shadow: 0px 2px 2px grey;
    
}

th, td {
    padding: 8px;
    border: 1px solid #ddd; 
}


  .sm_w{
    width: 150px;
  }
  .w_20{
    width: 200px;
  }
  .w_60{
    width: 270px;
    
  }



  .order-track {
    /* margin-top: 2rem; */
    padding: 0 1rem;
    /* border-top: 1px dashed #2c3e50; */
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
  }
  .order-track-step {
    display: flex;
    height: 7rem;
  }
  .order-track-step:last-child {
    overflow: hidden;
    height: 4rem;
  }
  .order-track-step:last-child .order-track-status span:last-of-type {
    display: none;
  }
  .order-track-status {
    margin-right: 1.5rem;
    position: relative;
  }
  .order-track-status-dot {
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #006BC2;
  }
  .order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    background: #006BC2;
  }
  .order-track-text-stat {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 3px;

  }
  .order-track-text-sub {
    font-size: 0.8rem;
    font-weight: 300;
    /* color:#006BC2; */
  }
  
  .order-track {
    transition: all 0.3s height 0.3s;
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }

  .sdd_flex{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  @media (max-width: 500px) {
    .sdd_flex{
      flex-direction:column !important;
    }
  }

 

.form_edit_box .form-group p{
  font-size: 14px ;
  margin: 0px;
  margin-bottom: 5px;
  
}
.form_edit_box .sm_label{
  font-size: 11px !important;
  color: red;
  margin: 0px;
  margin-top: -5px !important;

  margin-bottom: 7px;
}
.form_edit_box .form-control{
  box-shadow: none;
}
.listsize {
    width: 250px;
  }
  .nav-wid {
    background-color: #0082e6 !important;
    
  }
  .nav-wid a{
    color:white;
    font-size: 16px !important;
  }
  .nav-wid a:hover{
    color:white;
    text-decoration: none;
  }
  .MuiMenu-list {
    width: 250px !important;
    /* height: 250px !important; */
    overflow: auto;
  }
  /* .MuiMenu-paper {
    top: 50px !important;
  } */
  
  .MuiMenuItem-root {
    margin-bottom: 3px !important;
    /* border-bottom: 1px solid rgba(42, 159, 34, 0.5) !important; */
    /* background-color: rgb(14, 71, 52) !important; */
   

  }
  .MuiMenuItem-root a{
    display: flex;
    align-items: center;
    /* color:#000000; */
  }
  /* .MuiMenuItem-root a:hover{
    color: #000000;
    text-decoration: none;
    background-color: #026aba;
  }
.MuiButtonBase-root:hover a{
  
  color: white !important;
}
  .MuiList-root a:hover{
    background-color: #026aba;
    color: white !important;
    
  } */


  .MuiButtonBase-root:hover {
    background-color: #026aba !important;
    color: white !important;
    
  }
  .MuiList-root li a{
    color: black;
  }
  .MuiList-root li:hover a{
    color: white !important;
  }
  .MuiList-root li a:hover{
    text-decoration: none;
  }
  .MuiList-root li:hover{
    /* background-color: #026aba; */
    color: white !important;
    
  }
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
.new_invo h4{
font-weight: 500;
margin: 5px 0;
font-size: 16px;
}
.new_invo h4 span{
color: #006BC2;
}
  .new_invo .itemflex {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(27%, 1fr));
    grid-gap: 30px;
    gap: 30px;
    margin-top: 10px;
  }

  .new_invo .boxitem {
    width: 100%;
    height: auto;
    /* border: 1px solid brown; */
    padding: 10px;
    border-radius: 5px;
    
  }
  .new_invo .f_bold {
    font-weight: bolder;
    font-size: 20px;
  }
  .new_invo .fw_bold {
    font-weight: 600;
  }
 .new_invo p {
    font-size: 14px;
  }
 .new_invo .m-0 {
    margin:  0px;
  }
 .new_invo .mb-3 {
    margin-bottom: 15px;
  }
 .new_invo .right_div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    line-height: 35px;
  }
  .new_invo .right_div .bg-light {
    background-color: #eee;
    padding: 5px 10px;
  }
  .new_invo .flex_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px;
  }
  .new_invo .logo {
    width: 150px;
    height: 70px;
  }
  .new_invo .rightdiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 600;
  }

 
  .new_invo .mylist{
    margin-top: 30px;
    
  }
  .new_invo .mylist thead {
    background-color: #006BC2;
    height: 40px;
    color:white;
   
  }
  .new_invo .w_20 {
    width: 90px !important;
    font-weight: 500 !important;
  }
  
  .new_invo .w_60 {
    width: 300px !important;
    font-weight: 500 !important;
  }
  .new_invo .w_40 {
    width: 200px !important;
    font-weight: 500 !important;
  }

  .new_invo table {
    width: 100%;
    border-collapse: collapse;
    
        table-layout: fixed;
        overflow-x: scroll;
      
  }
  .new_invo .item_img {
    width: 75px;
    height: 75px;
    object-fit: contain;
    border-radius: 10px;
  }
  .new_invo .textdark {
    color: #555 !important;
    text-decoration: none;
  }
  .new_invo .textdark:hover {
    text-decoration: none;
  }
  .new_invo .total_div {
    width: 280px;
/* background-color: aqua; */
    
    padding: 15px;
    margin-right: -50px;
  }
  .new_invo .d-flex{
    display: flex;
    align-items: center;
    
  }
  
  
  .new_invo tbody td {
    padding: 15px 0;
    
  }
  
  .new_invo .fn_20{
        font-size: 14px;
        font-weight: 600;
    }
  
    .new_invo  .dd_flex
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid black;
        padding-bottom: 10px;
    }
    .new_invo  .boxitem .right_div .fp{
line-height:normal !important;
    }
    .new_invo  tbody tr{
        background: ghostwhite;
        height: 100px;
        padding: 5px ;
    }
    .new_invo tbody tr td{
        text-align: center;
        font-size: 12px;
    }
    .new_invo thead tr th{
      font-size: 12px;
    }
    .new_invo{
        width: 100%;
        height: 100vh;
        position: fixed;
        top:0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(85, 99, 206, 0.5);
        z-index: 99999;
    }

    .new_invo .invoice_box {
        /* max-width: 1000px; */
        width: 90%;
        height: 90vh;
        overflow-y: auto;
        margin: auto;
        padding:15px 20px  ;
    
        font-size: 20px;
    
        margin-top: 50px;
        color: #555;
        background-color: white;
      }
.student_nav{
  width: 100%;
  height: 60px;
 background-color: #0082e6;

  box-shadow: 0px 1px 5px grey;
  position:fixed;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.student_navbar{
  width: 95%;
  height: 100%;
  
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo_nav:hover{
  background-color: none !important;
}



.stu_logoimg{
  height: 50px !important;
    object-fit: contain;
}


.resp_dis{
  display: none !important;
}


.modbox{
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.smbox{
  min-width:300px ;
  height: auto;
  background-color: white;
  padding: 15px;
  position: relative;
}

.btn_close{
  width: 25px;
  height: 25px;
  background-color: #0082e6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:-10px;
  right: -10px;
  border-radius: 50%;
  color:white;
  cursor: pointer;
}






.whole__page{
  
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.left_side_navbar{
  position: fixed;
  top: 0;
  left: 0;
   width: 18%;
   height: 100vh;  
   overflow-y: auto;  
   background: white;
   padding-bottom: 50px;
 

}

.side_navbar_inner  a{
   
  display: block;
  color:black;

  padding: 10px ;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  width: 80%;
  
  margin: 5px;
  
}
.side_navbar_inner  a:hover{
  background-color: #0082e6;
  color:white
}
.side_navbar_inner  .active{
  background-color: #0082e6;
  color:white
}
.side_navbar_inner a:hover{
  text-decoration: none;
}

.side_navbar_inner{
    
  margin-top: 100px;
  
}

.main_div{
  position: absolute;
  top:0;
  right: 0;
  width: 82%;
  min-height: 100vh;
  /* font-size: 45px; */
  /* background-color: aquamarine; */
  background-color: ghostwhite;
  
  /* padding: 15px; */
  padding:  100px 0px 50px 0px;
}
.main_inner_div{
  width: 95%;
  display: block;
  margin: auto;
}


@media (max-width:776px) {
  .main_div{
    position: relative;
  }
  .resp_dis{
    display: block !important;
  }
  .left_side_navbar{
    display: none;
}

.main_div{
  width: 100%;
}

}
.accordion_whole {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* row-gap: 20px; */
}

.accordion_whole .accordion__item {
    width: 100%;
   
    /* background-color: grey; */
   padding: 5px;
   /* padding-left: 25px; */
box-shadow: 0px 1px 4px grey;
    color: #000000;
    overflow: hidden;
   
    margin: 15px 0;
}

.accordion_whole .accordion__header {
  
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}


.accordion_whole .accordion__header h4 {
    transition: 0.2s ease-in-out;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    color:#000000 ;

}

.accordion__content h4{
    font-size: 16px;
    
}
.accordion__content h4 span{
 color: #5d5d5d;  
 margin-right: 10px; 
}
.accordion_whole .accordion__header i {
    transition: 0.2s ease-in-out;
    -webkit-transform-origin: center;
            transform-origin: center;
    margin-bottom: 10px;
}

.accordion_whole .accordion__header:hover h4 {
    color: #0082e6;
}

.accordion_whole .accordion__header:hover i {
    color: #0082e6;
}

.accordion_whole .accordion__content {
    padding: 10px 0;
    border-top: 1px solid #000;
}


.enroll{
    width: 100%;
    padding: 120px 0 75px 0;
    min-height: 100vh;

}
.enrollbox{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.enroll .form-group p{
    font-size: 14px ;
    margin: 0px;
    margin-bottom: 5px;
    
}
.enroll .sm_label{
    font-size: 11px !important;
    color: red;
    margin: 0px;
    margin-top: -5px !important;
 
    margin-bottom: 7px;
}
.enroll .form-control{
    box-shadow: none;
}
.flexitem{
    display: flex;
    justify-content: end;
    margin-top: 5px;
}
.save_btn{
    /* width: 150px; */
    height: 40px;
    padding: 8px 15px;
    background-color: #006BC2;
    cursor: pointer;
    color: white;
}





@-webkit-keyframes ldio-3rgqhfk5x79 {
    0% { background: #5699d2 }
    12.5% { background: #5699d2 }
    12.625% { background: #1d3f72 }
    100% { background: #1d3f72 }
}





@keyframes ldio-3rgqhfk5x79 {
    0% { background: #5699d2 }
    12.5% { background: #5699d2 }
    12.625% { background: #1d3f72 }
    100% { background: #1d3f72 }
}
.ldio-3rgqhfk5x79 div {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #1d3f72;
  -webkit-animation: ldio-3rgqhfk5x79 1s linear infinite;
          animation: ldio-3rgqhfk5x79 1s linear infinite;
}
.loadingio-spinner-blocks-n2or5axwwk {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-3rgqhfk5x79 {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio-3rgqhfk5x79 div { box-sizing: content-box; }

.preview_box{
    width: 50px;
    height: 50px;
    position: fixed;
    position: fixed;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    
    background-color: #006BC2;
    z-index: 99999;
    cursor: pointer;
    color: white;
    /* border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;

}
.preview_box:hover{
background-color:#318dde;
}
.preview_modal th{
    min-width: 150px !important;
    font-size: 14px !important;
}

.preview_modal td{
    min-width: 200px !important;
    font-size: 14px !important;
}
.profile_previewimg{
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 10px;
}
.success_page{
    width:100%;
    min-height: 100vh;
    padding: 75px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

